import {ChangeDetectionStrategy, ChangeDetectorRef, Component} from '@angular/core';
import {LearnService} from '@modules/learn/services/learn.service';
import {MarkdownSection} from '@app/shared/services/markdown.service';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {MatAccordion, MatExpansionPanel, MatExpansionPanelHeader} from '@angular/material/expansion';
import {MarkdownComponent, provideMarkdown} from 'ngx-markdown';
import {CommonModule, NgForOf} from '@angular/common';
import {YoutubeVideoComponent} from '@modules/youtube-video/youtube-video.component';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, combineLatest} from 'rxjs';
import {FormControl, ReactiveFormsModule} from '@angular/forms';
import {debounceTime, map, startWith, tap} from 'rxjs/operators';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatIconModule} from '@angular/material/icon';
import {MatIconButton} from '@angular/material/button';
import {MatProgressSpinner} from '@angular/material/progress-spinner';

@UntilDestroy()
@Component({
  selector: 'app-learn-cco',
  templateUrl: './learn-cco.component.html',
  styleUrls: ['./learn-cco.component.scss'],
  standalone: true,
  providers: [provideMarkdown({ loader: HttpClient})],
  imports: [
    ReactiveFormsModule,
    CommonModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MarkdownComponent,
    MatAccordion,
    MatExpansionPanel,
    MatExpansionPanelHeader,
    NgForOf,
    YoutubeVideoComponent,
    MatIconButton,
    MatProgressSpinner,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LearnCcoComponent {
  private helpTopicsSource = new BehaviorSubject<MarkdownSection[]>([]);
  public helpTopics$ = this.helpTopicsSource.asObservable();
  searchTerm = new FormControl('');

  filteredHelpTopics$ = combineLatest([
    this.helpTopics$,
    this.searchTerm.valueChanges.pipe(startWith(''), debounceTime(300))
  ]).pipe(
    map(([sections, search]) => {
      if (!search.trim()) return sections;

      const escapedSearch = search.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
      const regex = new RegExp(`(${escapedSearch})`, 'gi');

      return sections
        .map((section) => {
          const filteredTopics = section.topics
            .filter(topic =>
              topic.title.toLowerCase().includes(search.toLowerCase()) ||
              topic.data.some(chunk => chunk.data.toLowerCase().includes(search.toLowerCase()))
            )
            .map(topic => ({
              ...topic,
              title: topic.title, // No highlighting for title
              data: topic.data.map(chunk => ({
                ...chunk,
                data: chunk.data.replace(regex, '<mark>$1</mark>') // Highlight in content
              }))
            }));

          return { category: section.category, topics: filteredTopics };
        })
        .filter(section => section.topics.length > 0);
    }),
    tap(() => this.changeDetectorRef.markForCheck())
  );

  constructor(protected learnService: LearnService, protected changeDetectorRef: ChangeDetectorRef) {
    this.learnService.getHelpDataTopics$()
      .pipe(untilDestroyed(this))
      .subscribe((data) => {
      this.helpTopicsSource.next(data);
      this.changeDetectorRef.markForCheck();
    });
  }

  clearSearch(): void {
    this.searchTerm.setValue('');
  }
}
