import {ChangeDetectionStrategy, ChangeDetectorRef, Component} from '@angular/core';
import {FlexModule} from '@angular/flex-layout';
import {MatIcon} from '@angular/material/icon';
import {ContextualHelpPanelService} from '@core/services/contextual-help-panel.service';
import {AsyncPipe, CommonModule} from '@angular/common';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-contextual-help-panel-trigger',
  templateUrl: './contextual-help-panel-trigger.component.html',
  styleUrls: ['./contextual-help-panel-trigger.component.scss'],
  standalone: true,
  imports: [
    FlexModule,
    MatIcon,
    AsyncPipe,
    CommonModule,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContextualHelpPanelTriggerComponent {

  constructor(protected helpPanelService: ContextualHelpPanelService, protected changeDetectorRef: ChangeDetectorRef) {
    this.helpPanelService.dataExistsForPage$
      .pipe(untilDestroyed(this))
      .subscribe((dataExistsForPage) => {
        if (dataExistsForPage) {
          this.changeDetectorRef.markForCheck();
        }
      });
  }

  toggleOpenHelpPanel() {
    this.helpPanelService.toggleHelpPanelOpen();
  }
}
