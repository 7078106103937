<div
  class="hidden sm:block"
  *ngIf="helpPanelService.dataExistsForPage$ | async"
>
  <div style="min-height: 10px"></div>
  <mat-icon
    *ngIf="!(helpPanelService.helpPanelOpen$ | async)"
    style="color: grey; cursor: pointer"
    (click)="toggleOpenHelpPanel()"
  >help</mat-icon>
</div>
