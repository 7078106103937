<mat-form-field style="width: 100%">
  <mat-label>Search Help Topics</mat-label>
  <input matInput [formControl]="searchTerm" placeholder="Search...">
  <mat-icon matSuffix *ngIf="!searchTerm.value">search</mat-icon>
  <button mat-icon-button matSuffix *ngIf="searchTerm.value" (click)="clearSearch()">
    <mat-icon >clear</mat-icon>
  </button>
</mat-form-field>

@if (!(filteredHelpTopics$ | async)) {
  <mat-spinner></mat-spinner>
} @else {
  <mat-accordion multi="false">
    <div *ngFor="let group of filteredHelpTopics$ | async">
      <h2 class="category-header">{{ group.category | titlecase }}</h2>
      <mat-expansion-panel *ngFor="let helpTopic of group.topics" [expanded]="false">
        <mat-expansion-panel-header>{{ helpTopic.title }}</mat-expansion-panel-header>
        <div *ngFor="let chunk of helpTopic.data">
          @if (chunk.type === 'markdown') {
            <markdown [data]="chunk.data"></markdown>
          }
          @if (chunk.type === 'video') {
            <app-youtube-video
              [videoId]="chunk.data"
              [width]="440"
              [height]="280"
            ></app-youtube-video>
          }
        </div>
      </mat-expansion-panel>
    </div>
  </mat-accordion>
}
