import {Injectable, OnDestroy} from '@angular/core';
import {BehaviorSubject, combineLatest, Observable, of, Subject, switchMap} from 'rxjs';
import {NavigationEnd, Router} from '@angular/router';
import {filter, map, shareReplay, takeUntil, tap} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {
  MarkdownTopic,
  MarkdownService,
  HelpMarkdownFileNamesSections,
} from '@app/shared/services/markdown.service';

@Injectable({ providedIn: 'any' })
export class ContextualHelpPanelService implements OnDestroy {
  public dataExistsForPage$ = new BehaviorSubject<boolean>(true);
  public helpPanelOpen$ = new BehaviorSubject(false);
  destroyed$ = new Subject<boolean>();
  pageData$ = new BehaviorSubject<MarkdownTopic[]>(null);
  indexData: HelpMarkdownFileNamesSections = null;

  constructor(
    private router: Router,
    private markdownService: MarkdownService,
    private http: HttpClient
  ) {

    combineLatest([
      this.router.events.pipe(
        filter(event => event instanceof NavigationEnd),
        map(event => event.url.replace('/app/', ''))
      ),
      this.helpPanelOpen$,
    ])
      .pipe(
        takeUntil(this.destroyed$),
        filter(([, isOpen]) => isOpen),
        switchMap(([fullPath]) =>
          this.loadIndexData$().pipe(
            map(() => this.getFilesForPath(fullPath)),
            switchMap(filesToLoad => this.markdownService.loadMultipleFiles$(filesToLoad))
          )
        )
      )
      .subscribe(parsedHelpTopics => this.pageData$.next(parsedHelpTopics || null));

    this.router.events
      .pipe(
        takeUntil(this.destroyed$),
        filter(event => event instanceof NavigationEnd),
        map(event => event.url.replace('/app/', '')),
        switchMap(url =>
          this.loadIndexData$().pipe(
            map(() => this.getFilesForPath(url))
          )
        )
      )
      .subscribe((filesToLoad) => {
        const dataExistsForPage = filesToLoad.length > 0;
        this.dataExistsForPage$.next(dataExistsForPage);

        if (!dataExistsForPage) {
          this.helpPanelOpen$.next(false);
        }
      });

  }

  private loadIndexData$(): Observable<HelpMarkdownFileNamesSections> {
    if (this.indexData) return of(this.indexData);

    return this.http.get<HelpMarkdownFileNamesSections>('assets/help-page-data/index.json').pipe(
      tap(data => this.indexData = data),
      shareReplay(1),
    );
  }

  private getFilesForPath(urlPath: string): string[] {
    if (!this.indexData) return [];

    const segments = urlPath.split('/').filter(Boolean);
    let currentLevel = this.indexData.sections;
    const filesToLoad: string[] = [];
    let fileFolder = '';

    for (const segment of segments) {
      // Sanitize the segment (e.g., replace hyphens with underscores, convert "new" or numbers to "id")
      const sanitizedSegment = isNaN(Number(segment)) && segment !== 'new' ? segment : 'id';

      const match = currentLevel.find(entry => entry.path === sanitizedSegment);

      if (!match) break;
      if (!fileFolder) fileFolder = match.path;

      filesToLoad.push(...(match.files || []));
      currentLevel = match.subPaths || [];
    }

    return filesToLoad.map(file => `${fileFolder}/${file}`);
  }


  toggleHelpPanelOpen() {
    this.helpPanelOpen$.next(!this.helpPanelOpen$.value);
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
  }
}
