import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {forkJoin, Observable, of, switchMap} from 'rxjs';
import {
  HelpMarkdownFileNamesSection,
  HelpMarkdownFileNamesSections,
  MarkdownSection,
  MarkdownService,
  MarkdownTopic
} from '@app/shared/services/markdown.service';
import {catchError, map} from 'rxjs/operators';

@Injectable({ providedIn: 'any' })
export class LearnService {
  constructor(private http: HttpClient, private markdownService: MarkdownService) {}

  getHelpDataTopics$(): Observable<MarkdownSection[]> {
    return this.http.get<HelpMarkdownFileNamesSections>('assets/help-page-data/index.json')
      .pipe(
        catchError(() => of({ sections: [] })),
        switchMap(({ sections }) => {
          if (!sections.length) return of([]);

          const categoryRequests = sections.map(({ path, category, files, subPaths }: HelpMarkdownFileNamesSection) => {
            const allFiles = [
              ...files.map(file => `${path}/${file}`),
              ...(subPaths ? subPaths.flatMap(sub => sub.files.map(file => `${path}/${file}`)) : [])
            ];

            const fileRequests = allFiles.map(filePath => this.markdownService.getParsedMarkdown$(filePath));

            return forkJoin(fileRequests).pipe(
              map((fileContents: MarkdownTopic[][]) => ({
                category,
                topics: fileContents.flat(),
              }))
            );
          });

          return forkJoin(categoryRequests);
        })
      );
  }
}
