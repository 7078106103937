import {Component} from '@angular/core';
import {MatDialogModule} from '@angular/material/dialog';
import {MatButton} from '@angular/material/button';
import {LearnCcoComponent} from '@modules/learn/components/learn-cco/learn-cco.component';
import {LearnMixersComponent} from '@modules/learn/components/learn-mixers/learn-mixers.component';
import {MatTab, MatTabGroup} from '@angular/material/tabs';
import {MatIconModule} from '@angular/material/icon';

@Component({
  selector: 'app-learn-dialog',
  templateUrl: './learn-dialog.component.html',
  styleUrls: ['./learn-dialog.component.scss'],
  standalone: true,
  imports: [
    MatDialogModule,
    MatIconModule,
    MatButton,
    LearnCcoComponent,
    LearnMixersComponent,
    MatTab,
    MatTabGroup,
  ],
})
export class LearnDialogComponent {}
