import {Injectable} from '@angular/core';
import {forkJoin, Observable, of} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {catchError, map} from 'rxjs/operators';

interface MarkdownChunk {
  type: 'markdown' | 'video';
  data: string;
}

export interface MarkdownTopic {
  title: string;
  data: MarkdownChunk[];
}

export interface MarkdownSection {
  category: string;
  topics: MarkdownTopic[];
}

export interface HelpMarkdownFileNamesSection {
  path: string;
  category: string;
  files: string[];
  subPaths: HelpMarkdownFileNamesSection[];
}

export interface HelpMarkdownFileNamesSections {
  sections: HelpMarkdownFileNamesSection[];
}

@Injectable({ providedIn: 'root' })
export class MarkdownService {
  private storageKey = 'cco-help-data';

  constructor(private http: HttpClient) {}

  private getCache(): Record<string, MarkdownTopic[]> {
    const cachedData = localStorage.getItem(this.storageKey);
    return cachedData ? JSON.parse(cachedData) : {};
  }

  private getParsedData(fileName: string): MarkdownTopic[] | null {
    return this.getCache()[fileName] || null;
  }

  private storeParsedData(fileName: string, data: MarkdownTopic[]): void {
    const cache = this.getCache();
    cache[fileName] = data;
    localStorage.setItem(this.storageKey, JSON.stringify(cache));
  }

  getParsedMarkdown$(fileName: string): Observable<MarkdownTopic[]> {
    const cachedData = this.getParsedData(fileName);
    if (cachedData) {
      return of(cachedData);
    }

    return this.http.get(`assets/help-page-data/${fileName}`, { responseType: 'text' }).pipe(
      catchError(() => of(null)),
      map((content) => {
        if (!content) return null;
        const parsed = this.parseFile(content);
        this.storeParsedData(fileName, parsed);
        return parsed;
      })
    );
  }

  loadMultipleFiles$(fileNames: string[]): Observable<MarkdownTopic[]> {
    if (!fileNames.length) return of([]);

    return forkJoin(fileNames.map(file => this.getParsedMarkdown$(file))).pipe(
      map(results => results.flat().filter(Boolean))
    );
  }

  parseFile(markdown: string): MarkdownTopic[] {
    if (!markdown.startsWith('#')) return [];

    const topics: MarkdownTopic[] = [];
    const sections = markdown.split(/^# /m).filter(Boolean);

    for (const section of sections) {
      const lines = section.split('\n');
      const title = lines.shift()?.trim() || 'Untitled';
      const chunks: MarkdownChunk[] = [];
      let currentMarkdown: string[] = [];

      for (const line of lines) {
        if (line.startsWith('![video]:')) {
          if (currentMarkdown.length) {
            chunks.push({ type: 'markdown', data: currentMarkdown.join('\n') });
            currentMarkdown = [];
          }
          chunks.push({ type: 'video', data: line.replace('![video]:', '').trim() });
        } else {
          currentMarkdown.push(line);
        }
      }

      if (currentMarkdown.length) {
        chunks.push({ type: 'markdown', data: currentMarkdown.join('\n') });
      }

      topics.push({ title, data: chunks });
    }

    return topics;
  }
}
