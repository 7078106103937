import {Component} from '@angular/core';
import {ContextualHelpPanelService} from '@core/services/contextual-help-panel.service';
import {AsyncPipe, CommonModule} from '@angular/common';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {MatIcon} from '@angular/material/icon';
import {YoutubeVideoComponent} from '@modules/youtube-video/youtube-video.component';
import {MarkdownModule, provideMarkdown} from 'ngx-markdown';
import {HttpClient} from '@angular/common/http';
import {MatExpansionModule} from '@angular/material/expansion';
import {MarkdownTopic} from '@app/shared/services/markdown.service';

@UntilDestroy()
@Component({
  selector: 'app-contextual-help-panel',
  templateUrl: './contextual-help-panel.component.html',
  styleUrls: ['./contextual-help-panel.component.scss'],
  standalone: true,
  providers: [provideMarkdown({ loader: HttpClient})],
  imports: [
    AsyncPipe,
    CommonModule,
    MatIcon,
    YoutubeVideoComponent,
    MarkdownModule,
    MatExpansionModule,
  ],
})
export class ContextualHelpPanelComponent {
  helpTopics: MarkdownTopic[];
  constructor(protected helpPanelService: ContextualHelpPanelService) {
    this.helpPanelService.pageData$
      .pipe(untilDestroyed(this))
      .subscribe((data: MarkdownTopic[]) => this.helpTopics = data);
  }
}
