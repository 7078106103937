import { Component, Input } from '@angular/core';
import {CommonModule} from '@angular/common';
import {SafeUrlPipe} from '@app/shared/pipes/safe-url.pipe';
import {YouTubePlayer} from '@angular/youtube-player';

@Component({
  selector: 'app-youtube-video',
  templateUrl: './youtube-video.component.html',
  styleUrls: ['./youtube-video.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    SafeUrlPipe,
    YouTubePlayer,
  ],
})
export class YoutubeVideoComponent {
  @Input() videoId: string;
  @Input() width = 500;
  @Input() height = 400;
}
