<h2 mat-dialog-title class="dialog-title"><mat-icon>school</mat-icon>Learn</h2>
<mat-dialog-content style="padding-bottom: 0.5em">
  <mat-tab-group>
    <mat-tab label="Concrete CloudOps™">
      <app-learn-cco></app-learn-cco>
    </mat-tab>
    <mat-tab label="Concrete Genius™ Mixers">
      <app-learn-mixers></app-learn-mixers>
    </mat-tab>
  </mat-tab-group>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-raised-button [mat-dialog-close]="true">Close</button>
</mat-dialog-actions>
