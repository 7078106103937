import {Component} from '@angular/core';

@Component({
  selector: 'app-learn-mixers',
  templateUrl: './learn-mixers.component.html',
  styleUrls: ['./learn-mixers.component.scss'],
  standalone: true,
  imports: [],
})
export class LearnMixersComponent {}
