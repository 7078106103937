<div style="background: white; border: 2px solid whitesmoke; padding-left: 3px; height: 100%; max-width: 450px">
  <div style="height: 10px"></div>
  <div class="flex justify-between items-center">
    <h2 class="help-panel-title leading-none">Helpful Information</h2>
    <mat-icon
      class="cursor-pointer text-gray-600 hover:text-gray-900 align-middle"
      (click)="helpPanelService.toggleHelpPanelOpen()">close</mat-icon>
  </div>

  <mat-accordion>
    <mat-expansion-panel *ngFor="let helpTopic of (helpPanelService.pageData$ | async)">
      <mat-expansion-panel-header>{{helpTopic.title}}</mat-expansion-panel-header>
      <div *ngFor="let chunk of helpTopic.data">
        @if (chunk.type === 'markdown') {
          <markdown [data]="chunk.data"></markdown>
        }
        @if (chunk.type === 'video') {
          <app-youtube-video
            [videoId]="chunk.data"
            [width]="440"
            [height]="280"
          ></app-youtube-video>
        }
      </div>
    </mat-expansion-panel>
  </mat-accordion>
  <div style="height: 3em"></div>
</div>

